.header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
    &_left_box{
        width: 500px;
        display: flex;
        height: 100px;
        align-items: center;
        margin-left: 20px;
        .text_box {
            .text{
                color: white;  
                font-size: 2.3em;  
                width: 13em;  
                height: 1.5em;
                border-right: 2px solid transparent;  
                animation: typing 4s steps(42, end) infinite, blink-caret .75s step-end infinite;  
                font-family:logoti;
                word-break: break-all;  
                overflow: hidden;
                font-weight: bold;
                background: linear-gradient(135deg,#5efce8,#736efe); 
                -webkit-background-clip: text; 
                background-clip: text;
                color: transparent; 
                animation-delay: 2s, 2s;  
            }
        }
        @keyframes typing {  
            from {  
            width: 0;  
            }  
            
            to {  
            width: 13em;  
            }  
        }  
        
        /* 光标 */  
        @keyframes blink-caret {        
            from,  
            to {  
            border-color: transparent;  
            }  
            
            50% {  
            border-color: currentColor;  
            }  
        }  
    }
    &_right_box{
        margin-right: 40px;
        .tabs_box{
            display: flex;
            font-size: 28px;          
            font-family: xingkai;
            font-style: italic;
            .tabs_title{
                margin-left: 50px;
                cursor: pointer;
            }
            .active{
                color:#f1c40f;
            }
            .tabs_title:hover{
                cursor: pointer;
                color:#f1c40f;
            }
        }
    }
}
.swiper-pagination-bullet {
    background-color: #3498db;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 0.6;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #e74c3c;
    opacity: 1;
  }
  
  /* 自定义导航箭头 */
  .swiper-button-next,
  .swiper-button-prev {
    color: #3498db;
  }