.app_header{
    height: 90px;
    &_item{
        width: 85%;
        margin: 0 auto;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.app_content{
    height: auto;
    &_item{
        width: 100%;
        height: 100%;
    }
} 
.app_footer{
    height: 100%;
    background-color: #393838;
    &_item{
        width: 85%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}