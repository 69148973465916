@font-face {
  font-family: 'canger';
  src:url('./style/fonts/canger.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'douyu';
  src:url('/style/fonts/douyu.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'logoti';
  src:url('/style/fonts/logoti.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'xingkai';
  src:url('/style/fonts/xingkai.ttf');
  font-weight: normal;
  font-style: normal;
}
body {
  margin: 0;
  user-select: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: canger;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
